/**  =====================
      Authentication css start
==========================  **/

.auth-main {
  position: relative;

  .auth-wrapper {
    height: 100%;
    width: 100%;
    min-height: 100vh;

    .saprator {
      position: relative;
      display: flex;
      align-self: center;
      justify-content: center;

      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 1px;
        background: var(--bs-border-color);
        z-index: 1;
      }

      span {
        font-size: 0.875rem;
        padding: 8px 24px;
        background: var(--bs-card-bg);
        z-index: 5;
        text-transform: capitalize;
        color: var(--pc-heading-color);
        font-weight: 500;
      }
    }
    &.v3 {
      display: flex;
      align-items: center;

      .auth-form {
        flex-direction: column;
        background: url("../images/authentication/img-auth-bg.svg");
        min-height: 100vh;
        padding: 24px;
        background-repeat: no-repeat;
        background-size: auto 75%;
        background-position: left center;
        position: relative;
        justify-content: space-between;
        > * {
          position: relative;
          z-index: 5;
        }
        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba($card-bg, 0.2);
          backdrop-filter: blur(16px);
        }
      }
    }
    .auth-form {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;

      .card {
        width: 100%;
        max-width: 480px;
        box-shadow: none;
      }

      img+span {
        padding-left: 10px;
      }

      h5 {
        span {
          text-decoration: underline;
        }
      }
    }

    .auth-footer,
    .auth-header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
/**====== Authentication css end ======**/