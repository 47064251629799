// ============================
//    11. Avtar css start
// ============================

.avtar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: $border-radius;
  font: {
    size: 18px;
    weight: 600;
  }
  width: 48px;
  height: 48px;

  &.avtar-xxs {
    width: 16px;
    height: 16px;
    font-size: 14px;
    border-radius: 5px;
  }

  &.avtar-xs {
    width: 32px;
    height: 32px;
    font-size: 12px;
  }

  &.avtar-s {
    width: 40px;
    height: 40px;
    font-size: 14px;
  }

  &.avtar-l {
    width: 64px;
    height: 64px;
    font-size: 20px;
  }

  &.avtar-xl {
    width: 80px;
    height: 80px;
    font-size: 24px;
  }

  &.avtar-square {
    border-radius: 4px;
    border: 2px solid;
  }

  &.avtar-icon {
    border: 2px solid $border-color;

    > img {
      width: 60%;
    }
  }
}
// ============================
//    11. Avtar css end
// ============================
